import React from 'react'

import './constrain.scss'

export default function Constrain({ children }) {
    return (
        <div className='constrain'>
            <div className='inner-padding'>
                { children }
            </div>
        </div>
    )
}
import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import './footer.scss'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import ExternalLink from '../ExternalLink'

import { useTranslation } from 'gatsby-plugin-react-i18next'
const date = new Date()

export default function Footer() {

    const { t } = useTranslation()

    const { title } = useSiteMetadata()

    return (
        <footer>
            <div className='container--max'>

                <div className='footer__grid'>

                    <div>
                        <div className='footerTitle'>{ t('benefits', 'Benefits') }</div>
                        <ul className='footerNavigation'>
                            <li><Link to='/benefits/centralized-messaging/'>{ t('Centralized messaging') }</Link></li>
                            <li><Link to='/benefits/customer-relationships/'>{ t('Customer relationships' )}</Link></li>
                            {/* <li><Link to='/'>Increased shop visibility</Link></li> */}
                            <li><Link to='/benefits/contactless-payment-options/'>{ t('Contactless payment options') }</Link></li>
                            {/* <li><Link to='/'>Easy account security</Link></li> */}
                            {/* <li><Link to='/'>Start getting paid in minutes</Link></li> */}
                            <li><Link to='/benefits/effortless-sign-in/'>{ t('Effortless sign in') }</Link></li>
                            <li><Link to='/benefits/nothing-to-upgrade-or-patch/'>{ t('Nothing to upgrade or patch') }</Link></li>
                            {/* <li><Link to='/'>Your data is safe</Link></li> */}
                        </ul>
                    </div>

                    <div>
                        <div className='footerTitle'>{ t('features', 'Features') }</div>
                        <ul className='footerNavigation'>
                            <li><Link to='/features/shop-management/'>{ t('Shop management') }</Link></li>
                            <li><Link to='/features/customer-notifications/'>{ t('Customer notifications') }</Link></li>
                            <li><Link to='/features/team-management/'>{ t('Team management') }</Link></li>
                            <li><Link to='/features/device-management/'>{ t('Device management') }</Link></li>
                            {/* <li><Link to='/'>Appointment reminders</Link></li>
                            <li><Link to='/'>Centralized messaging</Link></li>
                            <li><Link to='/'>Follow-up surveys</Link></li>
                            <li><Link to='/'>Online payments</Link></li>
                            <li><Link to='/'>Customer history</Link></li>
                             */}
                        </ul>
                    </div>

                    <div>
                        <div className='footerTitle'>{ t('Company') }</div>
                        <ul className='footerNavigation'>
                            {/* <li><Link to='/about'>About { title }</Link></li>
                            <li><Link to='/'>What is { title }?</Link></li> */}
                            <li><Link to='/contact'>{ t('Contact us') }</Link></li>
                            <li><ExternalLink href='https://support.napaconnect.ca'>{ t('Support') }</ExternalLink></li>
                            <li><ExternalLink href='https://status.napaconnect.ca'>{ t(`System status`) }</ExternalLink></li>
                        </ul>
                    </div>

                </div>

                <div className='legal'>
                    <ul>
                        <li><Link to='/terms-and-conditions'>{ t('Legal') }</Link></li>
                        <li><Link to='/privacy-policy'>{ t('Privacy') }</Link></li>
                    </ul>
                </div>

                <div className='copyright'>{ t('Copyright') } &copy; { date.getFullYear() } { title }.<br/>{ t('All rights reserved.') }</div>

            </div>
        </footer>
    )

}
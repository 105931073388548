import React, { useState, useEffect } from 'react'
import Constrain from 'components/Constrain'
import { useLocation } from '@reach/router'

import logo from './logo.svg'
import './header.scss'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'

export default function Header() {

    const { t, i18n } = useTranslation()
    const { changeLanguage } = useI18next()

    const { title, appUrl } = useSiteMetadata()

    const [expanded, setExpanded] = useState()

    const location = useLocation()

    useEffect(() => {
        setExpanded()
    }, [location])

    return (
        <header className={`header theme__background--primary ${ expanded ? 'header--expanded' : '' }`}>
            <Constrain>
                <div className='headerContainer'>
                    <Link to='/' className='logo'><img className='logo' src={ logo } alt={ title } /></Link>
                    <button className={`hamburgerMenu ${ expanded ? 'hamburgerMenu--active' : '' }`} onClick={() => setExpanded(expanded => !expanded)} aria-label='Navigation menu'>
                        <span className='hamburgerBox'>
                            <span className='hamburgerInner'></span>
                        </span>
                    </button>
                    <nav className={`menu ${ expanded ? 'menu--expanded' : '' }`}>
                        <ul className='nav-left'>
                            <li><Link to="/benefits/">{ t('benefits', 'Benefits') }</Link></li>
                            <li><Link to="/features/">{ t('features', 'Features') }</Link></li>
                            <li><Link to="/pricing/">{ t('pricing', 'Pricing') }</Link></li>
                            {/* <Link to="/blog">Blog</Link> */}
                            {/* <Link to="/dynamic">Dynamic</Link> */}
                        </ul>
                        <ul className='nav-right'>
                            <li><a href={ appUrl }>{ t('signIn', 'Sign in') }</a></li>
                            <li><a className='signUp' href={ `${ appUrl }/sign-up` }>{ t('signUp', 'Sign up') }</a></li>
                            <li><button type='button' className='language' onClick={() => changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ t(`languageSwitchSmall`, 'FR') }</button></li>
                        </ul>
                    </nav>
                </div>
            </Constrain>
        </header>
    )
}
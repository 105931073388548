import React from 'react'
import Constrain from 'components/Constrain'
import './title.scss'

export default function Home({ title, subtitle, dark }) {
    return (
        <Constrain>
            <div className={`pageTitle ${ dark ? 'pageTitle--dark' : '' }`}>
                <h1>{ title }</h1>
                <h2>{ subtitle  }</h2>
            </div>
        </Constrain>
    )
}